import React, { Component } from 'react';
import BookmarkItem from '../BookmarkItem/BookmarkItem';
import BookmarksContext from '../BookmarksContext'
import propTypes from 'prop-types';
import './BookmarkList.css'

class BookmarkList extends Component {
  constructor(props) {
    super(props);
  }
  static defaultProps = {
    bookmarks: []
  };

  handleDeleteBookmark = () => {
    this.props.history.push(`/`)
  }

  static contextType = BookmarksContext;
  render() {
    // console.log(this.props)
    // const { bookmarks } = this.props
    const { bookmarks } = this.context
    return (
      <section className='BookmarkList'>
        <h2>Your bookmarks</h2>
        <ul className='BookmarkList__list' aria-live='polite'>
          {bookmarks.map(bookmark =>
            <BookmarkItem
              key={bookmark.id}
              {...bookmark}
              history={this.props.history}
              onDeleteBookmark={this.handleDeleteBookmark}
            />
          )}
        </ul>
      </section>
    );
  }
}

export default BookmarkList;


BookmarkList.propTypes = {
  bookmarks: propTypes.arrayOf(propTypes.shape({
    title: propTypes.string.isRequired,
    url: propTypes.string.isRequired,
    rating: propTypes.number,
    description: propTypes.string
  }))
};