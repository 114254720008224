import React, { Component } from  'react';
import BookmarksContext from '../BookmarksContext';
import propTypes from 'prop-types';
import config from '../config'
import './EditBookmark.css';

const Required = () => (
  <span className='AddBookmark__required'>*</span>
)

class EditBookmark extends Component {
  static defaultProps = {
    updateBookmark: () => {}
  };
  // static propTypes = {
  //   title: propTypes.string.isRequired,
  //   url: propTypes.string.isRequired,
  //   rating: propTypes.number.isRequired,
  //   description: propTypes.string
  // }
  static propTypes = {
    match: propTypes.shape({
      params: propTypes.object,
    }),
    history: propTypes.shape({
      push: propTypes.func,
    }).isRequired,
  };
  static contextType = BookmarksContext;

  state = {
    id: '',
    title: '',
    url: '',
    rating: 1,
    description: '',
    error: null,
  };

  componentDidMount() {
    const { bookmarkId } = this.props.match.params
    fetch(config.API_ENDPOINT + `/${bookmarkId}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
      }
    })
    .then(res => {
        if (!res.ok)
          return res.json().then(error => Promise.reject(error))

        return res.json()
    })
    .then(bookmark => {
        this.setState({ 
          id: bookmark.id,
          title: bookmark.title,
          url: bookmark.url,
          rating: bookmark.rating,
          description: bookmark.description,
        })
    })
    .catch(error => this.setState({ error }))
  }

  handleChangeTitle = e => {
    this.setState({ title: e.target.value })
  };

  handleChangeUrl = e => {
    this.setState({ url: e.target.value })
  };

  handleChangeRating = e => {
    this.setState({ rating: e.target.value })
  };

  handleChangeDescription = e => {
    this.setState({ description: e.target.value })
  };

  handleSubmit = e => {
    e.preventDefault()
    const { bookmarkId } = this.props.match.params
    const { id, title, url, rating, description } = this.state
    const newBookmark = { id, title, url, rating, description }
    
    fetch(config.API_ENDPOINT + `/${bookmarkId}`, {
      method: 'PATCH',
      body: JSON.stringify(newBookmark),
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
      }
    })
   .then(res => {
     if (!res.ok)
      return res.json().then(error => Promise.reject(error))
   })
   .then(() => {
      this.setState({
        title: '',
        url: '',
        description: '',
        rating: 1,
      })
      this.context.updateBookmark(newBookmark)
      this.props.history.push('/')
   })
   .catch(error => {
      this.setState({ error })
   })
  }

  handleClickCancel = () => {
    this.props.history.push('/')
  }

  render() {
    const { title, url, rating, description, error } = this.state
    return (
      <section className='AddBookmark'>
        <h2>Edit Bookmark</h2>
        <form
          className='AddBookmark__form'
          onSubmit={this.handleSubmit}
        >
          <div className='AddBookmark__error' role='alert'>
            {error && <p>{error.message}</p>}
          </div>
          <input
            type='hidden'
            name='id'
          />
          <div>
            <label htmlFor='title'>
              Title
              {' '}
              <Required />
            </label>
            <input
              type='text'
              name='title'
              id='title'
              placeholder='Great website!'
              value={title}
              onChange={this.handleChangeTitle}
              required
            />
          </div>
          <div>
            <label htmlFor='url'>
              URL
              {' '}
              <Required />
            </label>
            <input
              type='url'
              name='url'
              id='url'
              placeholder='https://www.great-website.com/'
              value={url}
              onChange={this.handleChangeUrl}
              required
            />
          </div>
          <div>
            <label htmlFor='description'>
              Description
            </label>
            <textarea
              name='description'
              id='description'
              value={description}
              onChange={this.handleChangeDescription}
            />
          </div>
          <div>
            <label htmlFor='rating'>
              Rating
              {' '}
              <Required />
            </label>
            <input
              type='number'
              name='rating'
              id='rating'
              value={rating}
              onChange={this.handleChangeRating}
              min='1'
              max='5'
              required
            />
          </div>
          <div className='AddBookmark__buttons'>
            <button type='button' onClick={this.handleClickCancel} >
              Cancel
            </button>
            {' '}
            <button type='submit'>
              Save
            </button>
          </div>
        </form>
      </section>
    );
  }
}

export default EditBookmark;

//
//   static propTypes = {
//     match: PropTypes.shape({
//       params: PropTypes.object,
//     }),
//     history: PropTypes.shape({
//       push: PropTypes.func,
//     }).isRequired,
//   };

// title: propTypes.string.isRequired,
//   // url: propTypes.string.isRequired,
//   url: (props, propName, componentName) => {
//     //get the value of props
//     const prop = props[propName];

//     //do the isRequired check
//     if(!prop){
//       return new Error(`${propName} is required in ${componentName}. Validation Failed`);
//     }

//     //check the type
//     if(typeof prop != 'string'){
//       return new Error(`Invalid prop, ${propName} is expected to be a string in ${componentName}. ${typeof prop} found.`)
//     }

//     //do the custom check here using a simple regex
//     if(prop.length < 5 || !prop.match(new RegExp('/^https?:\/\//'))){
//       return new Error(`Invalid prop, ${propName} must a min length of 5 and begin with http(s)://. Validation Failed.`)
//     }
//   },
//   rating: propTypes.number,
//   description: propTypes.string
// }